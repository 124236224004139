@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

@import 'sass/reset';
@import 'sass/helpers';
@import 'sass/devices';

:root {
  // --accent is set in header.php

  --gutter: 5rem;

  --font-size--regular: 1.8rem;
  --line-height--regular: 1.4;

  --font-size--medium: 4rem;
  --line-height--medium: 1.4;

  --font-size--large: 6.0rem;
  --line-height--large: 1.2;

  @include devices (mobile, tablet) {
    --gutter: 3rem;

    --font-size--regular: 1.8rem;
    --line-height--regular: 1.4;

    --font-size--medium: 2.4rem;
    --line-height--medium: 1.4;

    --font-size--large: 3.6rem;
    --line-height--large: 1.2;
  }
}

@mixin font-size ($suffix: regular) {
  font-size: var(--font-size--#{$suffix});
  line-height: var(--line-height--#{$suffix});
}

@mixin text-shadow {
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
}

@import 'sass/base';

@import 'components/Contact';
@import 'components/Home';
@import 'components/Menu';
@import 'components/Page';

@include devices (mobile, tablet) {
  .home + #home .page__cover {
    display: none;
  }
}
