html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*::before,
*::after { box-sizing: inherit; }

body {
  @include font-size;

  min-height: 100%;
  margin: 0;
  padding: 0;

  font-family: 'Roboto', sans-serif;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  background: white;
  color: black;
}

::selection  {
  background: var(--accent);
  color: black;
  text-shadow: none;
}

img {
  @include no-select;

  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;
  width: 100%;
  height: auto;

  &[data-lazyload] {
    opacity: 0;
    transform: scale(1.05);
  }

  &[data-lazyload][data-loaded=true][data-fully-loaded] {
    @include easing(1000ms);
    opacity: 1;
    transform: none;
  }
}

a {
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  text-decoration: none;
  color: inherit;
  border-bottom: 1px solid transparent;

  .text & {
    border-bottom-color: currentColor;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

.text p {
  margin-bottom: 1em;
  line-height: var(--line-height);
}

.mobile-only {
  @include desktop {
    display: none;
  }
}

html {
  scroll-behavior: smooth;
  @include desktop {
    scroll-snap-type: y proximity;
  }

  &.no-snap {
    scroll-snap-type: none;
  }
}
