.page {
  position: relative;
  display: flex;
  min-height: 100vh;

  scroll-snap-align: start;

  @include devices (mobile, tablet) {
    flex-direction: column;
  }

  &:not(:last-child) {
    margin-bottom: calc(var(--gutter) / 2);
  }

  &__cover {
    position: sticky;
    top: 0;
    height: 100vh;
    flex: 50%;

    @include devices (mobile, tablet) {
      position: relative;
      min-height: 40vh;
    }

    figure {
      @include inset;
      overflow: hidden;
      background: black;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 0 0;
      }
    }

    header {
      @include font-size(large);
      @include text-shadow;

      display: flex;
      flex-direction: column;
      justify-content: safe center;
      height: 100%;

      position: absolute;
      color: white;
      padding: var(--gutter);
      right: 0;
      text-align: right;

      @include devices (mobile, tablet) {
        position: relative;
        max-width: 80vw;
        margin: 0 auto;
        text-align: center;
      }
    }
  }

  &__content {
    flex: 50%;
    padding: var(--gutter);
    display: grid;
    place-items: center start;

    .text {
      position: relative;
      max-width: 60ch;

      @include devices (mobile, tablet) {
        margin: 0 auto;
        &.has-excerpt {
          padding-bottom: 10rem;
        }
      }

      p {
        text-align: justify;
      }

      h4 {
        font-size: 1.4em;
        line-height: 1.3;
        margin: 3em 0 1.5em;
      }
    }
  }

  &__show-more {
    position: absolute;
    border: 0;
    text-transform: lowercase;
    margin-top: 5rem;

    right: 0;

    &::before {
      content: '•••';
      display: block;
      font-weight: bold;
      font-size: 5rem;
      text-align: right;
      line-height: 0;
      margin-bottom: 2rem;
    }
  }
}
