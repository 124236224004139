.contact {
  &__locations {
    display: flex;
    flex-direction: column;
  }

  &__location {
    position: relative;
    height: 100%;

    @include devices (mobile, tablet) {
      display: none;
    }
  }

  header {
    @include easing(1000ms, opacity);
    pointer-events: none;
  }

  .page__title {
    text-shadow: none;
  }

  .page__title.mobile-only {
    @include font-size(large);
    @include text-shadow;

    position: absolute;
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  @include desktop {
    &__location::before {
      @include easing(1000ms, opacity);
      @include inset;
      content: '';
      pointer-events: none;
      background: rgba(0, 87, 132, 0.5);
    }

    &__location:hover::before {
      opacity: 0;
    }

    &__location:hover header {
      opacity: 0;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
