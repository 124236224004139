.home {
  position: relative;
  scroll-snap-align: start;
  height: 100vh;

  display: grid;
  place-items: center;

  &:not(:last-child) {
    margin-bottom: calc(var(--gutter) / 2);
  }

  figure {
    @include inset;
    z-index: -1;
    overflow: hidden;
    background: black;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  header {
    @include font-size(large);
    @include text-shadow;
    @include no-select;

    text-align: center;
    width: 100%;

    h1 {
      color: white;
      font-weight: 400;
      margin-bottom: 0 !important;
    }

    h2 {
      @include font-size(medium);
      color: var(--accent);
      font-weight: 700;
      margin-top: 0;
      max-width: 80%;
      margin: 0 auto;
    }
  }

}
