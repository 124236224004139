@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(calc(var(--gutter) / 2));
  }

  to {
    opacity: 1;
    transform: 0;
  }
}

.menu {
  #toggleMenu { display: none; }

  &__button {
    cursor: pointer;
    position: fixed;
    z-index: 10;
    border-radius: 100%;
    color: white;
    background: rgba(0, 0, 32, 0.9);
    top: var(--gutter);
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    font-size: 3rem;
    width: 2em;
    height: 2em;
    line-height: 2em;

    @include devices (mobile, tablet) {
      top: calc(var(--gutter) * 1.25);
    }

    &:hover {
      color: var(--accent);
    }

    &::after {
      @include easing(300ms, color);
      content: '✕';
      font-weight: bold;
    }
  }

  #toggleMenu:not(:checked) ~ &__button {
    background: white;

    @include devices (mobile, tablet) {
      background: var(--accent);
    }
  }

  #toggleMenu:not(:checked) ~ &__button::after {
    color: black;
    content: '☰';
    font-weight: normal;
  }

  &__content {
    @include easing(400ms, opacity);
    @include inset(0, fixed);

    z-index: 9;
    background: rgba(0, 0, 32, 0.9);

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: scroll;
    padding: var(--gutter) 0;
  }

  #toggleMenu:not(:checked) ~ &__content:not(:focus-within) {
    opacity: 0;
    pointer-events: none;
  }

  &__items {
    @include font-size(medium);
    display: flex;
    flex-direction: column;
  }

  &__item {
    @include easing(300ms, color);
    color: white;
    width: fit-content;

    &:not(:last-child) { margin-bottom: 1em; }
    &:hover { color: var(--accent); }
  }


  #toggleMenu:checked ~ &__content &__items &__item {
    animation: fade-in 500ms both;
    animation-delay: calc(var(--index) * 50ms);
  }
}
